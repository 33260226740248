import { captureException, setUser as SentrySetUser } from '@sentry/browser';
import { addDays, isBefore, parseISO } from 'date-fns';
import get from 'lodash-es/get';
import merge from 'lodash-es/merge';
import set from 'lodash-es/set';
import { computed, reactive, watch } from 'vue';

import { Prospect, ProspectAddress } from '@/api/prospect/types';
import { useState } from '@/composables/states/useState';
import { useCookies } from '@/composables/utils/useCookies';
import { useStorage } from '@/composables/utils/useStorage';
import { ProductEnum } from '@/types/Product';
import { ProspectPersisted } from '@/types/Prospect';

const PERSIST_DAYS_TO_EXPIRATION = 45;

const prospectData = reactive({}) as Prospect;
const { stateData } = useState();
const { mergeCookie, CookieNamesEnum } = useCookies();
const { setInLocalStorage, removeFromLocalStorage, getFromLocalStorage } = useStorage();

const setProspectData = <T>(path: string, data: T) => {
  set(prospectData, path, data);
};
const getProspectData = <T>(path: string): T => get(prospectData, path);

const persistProspect = () => {
  setInLocalStorage('prospect_persisted', {
    prospect: prospectData,
    created: new Date()
  });
};
const persistProspectE2E = () => {
  setInLocalStorage('prospect_e2e', prospectData);
};

const getPersistedProspect = () => {
  try {
    const persistedProspect: ProspectPersisted | undefined = <ProspectPersisted | undefined>(
      getFromLocalStorage('prospect_persisted')
    );
    if (persistedProspect) {
      const data = persistedProspect.prospect;
      const dateLimit = addDays(parseISO(persistedProspect.created), PERSIST_DAYS_TO_EXPIRATION);
      if (isBefore(new Date(), dateLimit)) {
        return data;
      } else {
        removePersistedProspect();
      }
    }
    return;
  } catch (err) {
    window.Logger.$logError('getPersistedProspect failed', err);
    captureException(err);
  }
};

const removePersistedProspect = () => {
  removeFromLocalStorage(['prospect_persisted']);
};

const resetProspectKey = () => {
  setProspectData(PROSPECT_DATA_PATH.key, undefined);
  mergeCookie(CookieNamesEnum.LK_CMS, { pk: undefined });
};

const resetProspect = () => {
  for (const key in prospectData) delete prospectData[key];
  removeFromLocalStorage(['prospect_key']);
  removeFromLocalStorage(['prospect_persisted']);
  mergeCookie(CookieNamesEnum.LK_CMS, { pk: undefined });
};

const selectedOptions = computed({
  get: () => getProspectData<string[]>(PROSPECT_DATA_PATH.optional_bundles) || [],
  set: (bundles) => setProspectData<string[]>(PROSPECT_DATA_PATH.optional_bundles, bundles)
});

const getAddressPath = (product: ProductEnum) => {
  if (product === ProductEnum.FR_MORTGAGE) {
    return MORTGAGE_PROSPECT_DATA_PATH.main_asset;
  }

  if (product === ProductEnum.FR_ESCOOTER) {
    return ESCOOTER_PROSPECT_DATA_PATH.main_asset;
  }
  return PROSPECT_DATA_PATH.main_asset;
};

const getFullAddress = (source?: ProspectAddress) => {
  if (!source) return '';

  const { address_main, postcode, city, country } = source;

  if (!address_main || !postcode || !city || !country) return '';
  return `${address_main}, ${postcode} ${city}, ${country}`;
};

// This reactive object used for checking address validation
const prospectAddress = computed(() => {
  const path = getAddressPath(getProspectData(PROSPECT_DATA_PATH.product));

  const source: ProspectAddress | undefined = getProspectData(path);
  return {
    full_address: getFullAddress(source),
    address_main: source?.address_main,
    postcode: source?.postcode,
    city: source?.city,
    country: source?.country,
    country_code: source?.country_code,
    address_longitude: source?.address_longitude,
    address_latitude: source?.address_latitude
  };
});

// When we update Address in onboarding, always update prospectData.user.subscriber
// This watch hook will save address into the proper prospect path
watch(
  [
    () => prospectData.product,
    () => prospectData.user?.subscriber?.address_main,
    () => prospectData.user?.subscriber?.postcode,
    () => prospectData.user?.subscriber?.city,
    () => prospectData.user?.subscriber?.country,
    () => prospectData.user?.subscriber?.country_code
  ],
  ([newProduct]) => {
    if (window.location.hash.includes('MortgagePersonalInfo')) return;

    const path = getAddressPath(newProduct);
    const defaultData = getProspectData(getAddressPath(newProduct));

    const mergedData = merge(defaultData, {
      address_main: prospectData.user?.subscriber?.address_main,
      postcode: prospectData.user?.subscriber?.postcode,
      city: prospectData.user?.subscriber?.city,
      country: prospectData.user?.subscriber?.country,
      country_code: prospectData.user?.subscriber?.country_code,
      address_latitude: prospectData.user?.subscriber?.address_latitude,
      address_longitude: prospectData.user?.subscriber?.address_longitude
    });

    setProspectData(path, mergedData);
  }
);

watch(prospectData, (data: Prospect) => {
  stateData.value.needsTermination = data.need_termination;
});

// Set Sentry user email & id
watch(
  () => [prospectData.user?.subscriber?.email, prospectData.key],
  ([email, key]) => {
    if (key) {
      setInLocalStorage('prospect_key', key);
    }
    if (!email) return;

    SentrySetUser({ email, id: key });
  }
);

const PROSPECT_DATA_PATH = {
  main_asset: 'home.main_asset',
  contract_id: 'contract.id',

  address_main: 'home.main_asset.address_main', // 210, Quai de Jemmapes
  city: 'home.main_asset.city', // Paris
  country: 'home.main_asset.country', // France
  postcode: 'home.main_asset.postcode', // 75015
  country_code: 'home.main_asset.country_code', // FR
  address_latitude: 'home.main_asset.address_latitude',
  address_longitude: 'home.main_asset.address_longitude',
  main_asset_type: 'home.main_asset.type',
  content_value: 'home.main_asset.content_value',
  nb_rooms: 'home.main_asset.nb_rooms',
  floors_nb: 'home.main_asset.floors_nb',
  floor_level: 'home.main_asset.floor_level',
  surface: 'home.main_asset.surface',
  year_built: 'home.main_asset.year_built',
  owner_type: 'home.main_asset.owner_type',
  is_main: 'home.main_asset.is_main',
  building_value: 'home.main_asset.building_value',
  monthly_rent_with_charges_in_cents: 'home.main_asset.monthly_rent_with_charges_in_cents',
  furnished_status: 'home.main_asset.furnished_status',

  outbuilding_list: 'outbuilding.outbuilding',
  outbuilding_surface_range: 'home.main_asset.outbuilding_surface_range',
  outbuilding_size: 'home.main_asset.outbuilding_size',

  default_content_value: 'home.main_asset.default_content_value',
  has_chimney: 'home.main_asset.has_chimney',
  has_fire_alarm: 'home.main_asset.has_fire_alarm',
  has_theft_alarm: 'home.main_asset.has_theft_alarm',
  has_veranda: 'home.main_asset.has_veranda',
  has_solar_panels: 'home.main_asset.has_solar_panels',
  has_swimmingpool: 'home.main_asset.has_swimmingpool',
  occupant_type: 'home.main_asset.occupant_type',
  house_type: 'home.main_asset.house_type',

  parcels_json: 'prospectData.home.main_asset.parcels_json',

  user_object: 'user',
  subscriber_object: 'user.subscriber',
  subscriber: {
    first_name: 'user.subscriber.first_name',
    last_name: 'user.subscriber.last_name',
    birth_date: 'user.subscriber.birth_date',
    email: 'user.subscriber.email',
    phone: 'user.subscriber.phone',
    birth_country_code: 'user.subscriber.birth_country_code',

    is_sci: 'user.subscriber.is_sci',
    company: 'user.subscriber.company',

    address_main: 'user.subscriber.address_main', // 210, Quai de Jemmapes
    city: 'user.subscriber.city', // Paris
    country: 'user.subscriber.country', // France
    postcode: 'user.subscriber.postcode', // 75015
    country_code: 'user.subscriber.country_code', // FR
    address_latitude: 'user.subscriber.address_latitude',
    address_longitude: 'user.subscriber.address_longitude',

    has_authorized_mailing: 'user.subscriber.has_authorized_mailing',
    additional_data: {
      external_id: 'user.subscriber.additional_data.external_id'
    }
  },

  need_termination: 'need_termination',
  termination_reason: 'termination_reason',
  termination_country: 'termination_country',
  had_contract_terminated_past_3_years: 'had_contract_terminated_past_3_years',
  termination_exit_inventory_date: 'termination_exit_inventory_date',
  has_loan: 'termination_has_loan',
  termination_subscription_date: 'termination_subscription_date',
  start_date: 'start_date',

  is_cancelled: 'is_cancelled',
  email_validated: 'email_validated',

  optional_bundles: 'optional_bundles',
  valuable_list: 'valuable.valuable',
  deductible: 'deductible',
  package: 'package',

  address_complement_second_line: 'home.main_asset.address_complement_second_line',
  address_complement_appartment: 'home.main_asset.address_complement_appartment',
  address_complement: 'home.main_asset.address_complement',

  claim_history_main: 'home.main_asset.claim_history',
  claim_history_list_main: 'home.main_asset.claim_history_list',

  liability: 'parameters.liability',

  product: 'product',
  key: 'key',
  sign_url: 'sign_url',
  options: 'options',

  bike_price: 'parameters.bike_price',
  coupon: 'coupon',
  main_beneficiary: 'main_beneficiary',

  roommate_list: 'user.roommate',
  child_list: 'user.child',
  others_list: 'user.other',
  spouse: 'user.spouse',

  billing_frequency: 'billing_frequency',
  moving_contract_id: 'moving_contract_id',

  other_info_object: 'other_info',
  other_info: {
    building_value: 'other_info.building_value',
    is_hypothecated: 'other_info.is_hypothecated',
    former_insurance_yearly_price: 'other_info.former_insurance_yearly_price',
    source: 'other_info.source',
    source_rank: 'other_info.source_rank',
    churn_duration: 'other_info.churn_duration',
    termination_less_than_a_year: 'other_info.termination_less_than_a_year', // Onboarding only, not standard spec
    living_situation: 'other_info.living_situation',
    utm_params: 'other_info.utm_params',
    flat_outbuildings: 'other_info.flat_outbuildings'
  },

  has_outbuildings: 'has_outbuildings', // Onboarding only, not standard spec
  recaptcha: {
    token: 'recaptcha.token'
  },
  legal_pdf_sent_date: 'legal_pdf_sent_date'
};

const MORTGAGE_PROSPECT_DATA_PATH = {
  main_asset: 'mortgage.main_asset',

  main_asset_type: 'mortgage.main_asset.type',
  purpose: 'mortgage.main_asset.purpose',
  bank_name: 'mortgage.main_asset.bank_name',
  signature_date: 'mortgage.main_asset.signature_date',

  loan_list: 'loan.loan',

  subscriber_object: 'user.subscriber',
  subscriber: {
    first_name: 'user.subscriber.first_name',
    last_name: 'user.subscriber.last_name',
    birth_date: 'user.subscriber.birth_date',
    birth_country_code: 'user.subscriber.birth_country_code',
    email: 'user.subscriber.email',
    phone: 'user.subscriber.phone',

    is_sci: 'user.subscriber.is_sci',
    company: 'user.subscriber.company',

    address_main: 'user.subscriber.address_main', // 210, Quai de Jemmapes
    city: 'user.subscriber.city', // Paris
    country: 'user.subscriber.country', // France
    postcode: 'user.subscriber.postcode', // 75015
    country_code: 'user.subscriber.country_code', // FR
    address_latitude: 'user.subscriber.address_latitude',
    address_longitude: 'user.subscriber.address_longitude',

    gender: 'user.subscriber.gender',
    profession_category: 'user.subscriber.profession_category',
    quotity: 'user.subscriber.mortgage_quotity',
    initial_quotity: 'user.subscriber.initial_loan_quotity',
    is_smoker: 'user.subscriber.is_smoker',

    has_authorized_mailing: 'user.subscriber.has_authorized_mailing',
    additional_data: {
      external_id: 'user.subscriber.additional_data.external_id'
    }
  },

  borrower_list: 'user.borrower',
  first_borrower: 'user.borrower[0]',
  borrower: {
    first_name: 'user.borrower[0].first_name',
    last_name: 'user.borrower[0].last_name',
    birth_date: 'user.borrower[0].birth_date',
    birth_country_code: 'user.borrower[0].birth_country_code',
    gender: 'user.borrower[0].gender',

    profession_category: 'user.borrower[0].profession_category',
    email: 'user.borrower[0].email',
    phone: 'user.borrower[0].phone',
    is_sci: 'user.borrower[0].is_sci',
    company: 'user.borrower[0].company',

    address_main: 'user.borrower[0].address_main', // 210, Quai de Jemmapes
    city: 'user.borrower[0].city', // Paris
    country: 'user.borrower[0].country', // France
    postcode: 'user.borrower[0].postcode', // 75015
    country_code: 'user.borrower[0].country_code', // FR
    address_latitude: 'user.borrower[0].address_latitude',
    address_longitude: 'user.borrower[0].address_longitude',

    quotity: 'user.borrower[0].mortgage_quotity',
    initial_quotity: 'user.borrower[0].initial_loan_quotity',
    is_smoker: 'user.borrower[0].is_smoker'
  }
};

const ESCOOTER_PROSPECT_DATA_PATH = {
  main_asset: 'nvei.main_asset',
  type: 'nvei.main_asset.type',
  brand: 'nvei.main_asset.brand',
  model: 'nvei.main_asset.model',
  price: 'nvei.main_asset.price',
  usage: 'nvei.main_asset.usage',
  faster25: 'nvei.main_asset.faster25',
  address_main: 'nvei.main_asset.address_main', // 210, Quai de Jemmapes
  city: 'nvei.main_asset.city', // Paris
  country: 'nvei.main_asset.country', // France
  postcode: 'nvei.main_asset.postcode', // 75015
  country_code: 'nvei.main_asset.country_code', // FR
  address_latitude: 'nvei.main_asset.address_latitude',
  address_longitude: 'nvei.main_asset.address_longitude',
  is_relative: 'nvei.main_asset.is_relative',
  serial_number: 'nvei.main_asset.serial_number',
  individual_accident_limit: 'parameters.individual_accident_limit',

  subscriber: {
    email: 'user.subscriber.email',
    additional_data: {
      external_id: 'user.subscriber.additional_data.external_id'
    }
  }
};

const PET_PROSPECT_DATA_PATH = {
  main_asset: 'pet.main_asset',

  breed: 'pet.main_asset.breed',
  birthdate: 'pet.main_asset.birth_date',
  name: 'pet.main_asset.name',
  gender: 'pet.main_asset.gender',
  type: 'pet.main_asset.type',
  chip_id: 'pet.main_asset.chip_id',
  has_illness: 'pet.main_asset.has_illness',
  illness: 'pet.main_asset.illness',

  subscriber_object: 'user.subscriber',
  subscriber: {
    first_name: 'user.subscriber.first_name',
    last_name: 'user.subscriber.last_name',
    birth_date: 'user.subscriber.birth_date',
    email: 'user.subscriber.email',
    phone: 'user.subscriber.phone',

    address_main: 'user.subscriber.address_main', // 210, Quai de Jemmapes
    city: 'user.subscriber.city', // Paris
    country: 'user.subscriber.country', // France
    postcode: 'user.subscriber.postcode', // 75015
    country_code: 'user.subscriber.country_code', // FR
    additional_data: {
      external_id: 'user.subscriber.additional_data.external_id'
    }
  },

  product: 'product'
};

export const useProspect = () => ({
  prospectData,
  selectedOptions,
  prospectAddress,
  getFullAddress,
  setProspectData,
  getProspectData,
  resetProspect,
  resetProspectKey,
  persistProspect,
  persistProspectE2E,
  removePersistedProspect,
  getPersistedProspect,
  PROSPECT_DATA_PATH,
  MORTGAGE_PROSPECT_DATA_PATH,
  ESCOOTER_PROSPECT_DATA_PATH,
  PET_PROSPECT_DATA_PATH,
  getAddressPath
});
