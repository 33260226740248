export enum OutbuildingSizeEnum {
  SMALL = 'small',
  BIG = 'big',
  BIGGER = 'bigger',
  NONE = 'none',
  UNSUPPORTED = 'unsupported'
}

export enum OutbuildingTypeEnum {
  GARAGE = 'garage',
  BOX = 'box',
  CELLAR = 'cellar',
  ATTIC = 'attic',
  OTHER = 'other'
}
