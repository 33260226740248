export enum ClaimHistoryEnum {
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  TWO_PLUS = '2+',
  THREE_PLUS = '3+',
  UNKNOWN = 'Unknown',
  MISSING = 'Missing',
  MAX_CLAIMS_DE = 1,
  MAX_CLAIMS = 2
}

export enum ClaimHistoryTypeEnum {
  WATER_DAMAGE = 'water_damage',
  GLASS_BREAKAGE = 'glass_breakage',
  THEFT_OR_VANDALISM = 'theft_or_vandalism',
  CIVIL_LIABILITY = 'civil_liability',
  CLIMATE_EVENT = 'climate_event',
  FIRE = 'fire',
  ELECTRIC_DAMAGE = 'electric_damage',
  OTHER = 'other'
}

export enum ClaimHistoryOccurrencePlaceEnum {
  CURRENT_HOME = 'current_home',
  PAST_HOME = 'past_home'
}

export type ClaimDetail = {
  type: ClaimHistoryTypeEnum;
  occurrence_year: number;
  occurrence_place: ClaimHistoryOccurrencePlaceEnum;
};
